import { SSRBrokenRequest } from "@/api/axios";
import queryString from "query-string";

export const getSendTRXUSDT = async (data) => {
  const res = await SSRBrokenRequest({
    url: "/transaction/send/trx/usdt",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const getCheckBalance = async (data) => {
  const res = await SSRBrokenRequest({
    url: "/transaction/check/balance",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const getCallTimePrice = async (data) => {
  const res = await SSRBrokenRequest({
    url: "/transaction/get/check_price/call_time",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};
