<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_2619_306944)">
      <path
        d="M12.4453 17.7734C11.9023 17.7734 11.4648 17.3359 11.4648 16.793C11.4414 14.168 9.29297 12.0586 6.66797 12.082C4.07812 12.1055 1.98047 14.1992 1.95703 16.793C1.95703 17.3359 1.51953 17.7734 0.976562 17.7734C0.4375 17.7734 0 17.3359 0 16.793C0 13.0898 3.01172 10.0781 6.71484 10.0781C10.418 10.0781 13.4297 13.0898 13.4297 16.793C13.4258 17.3359 12.9883 17.7734 12.4453 17.7734Z"
        fill="#FFFFFF"
      />
      <path
        d="M6.71484 9.01172C4.83984 9.01172 3.32031 7.49219 3.32031 5.61719C3.32031 3.74219 4.83984 2.22656 6.71094 2.22656C8.58203 2.22656 10.1055 3.74609 10.1055 5.62109C10.1016 7.49219 8.58594 9.01172 6.71484 9.01172ZM6.71484 4.1875C5.92187 4.1875 5.28125 4.82812 5.28125 5.62109C5.28125 6.41406 5.92187 7.05469 6.71484 7.05469C7.50781 7.05469 8.14844 6.41406 8.14844 5.62109C8.14453 4.82812 7.50391 4.1875 6.71484 4.1875Z"
        fill="#FFFFFF"
      />
      <path
        d="M19.0195 6.14453H14.0586C13.5156 6.14453 13.0781 5.70703 13.0781 5.16406C13.0781 4.62109 13.5156 4.18359 14.0586 4.18359H19.0195C19.5625 4.18359 20 4.62109 20 5.16406C20 5.70312 19.5625 6.14453 19.0195 6.14453Z"
        fill="#FFFFFF"
      />
      <path
        d="M19.0198 9.38281H14.0588C13.5159 9.39453 13.0705 8.96484 13.0588 8.42188C13.0471 7.87891 13.4768 7.43359 14.0198 7.42188H14.0588H19.0198C19.5627 7.43359 19.9924 7.87891 19.9807 8.42188C19.969 8.94922 19.5471 9.375 19.0198 9.38281Z"
        fill="#FFFFFF"
      />
      <path
        d="M19.0195 12.625H14.0586C13.5156 12.625 13.0781 12.1875 13.0781 11.6445C13.0781 11.1016 13.5156 10.6641 14.0586 10.6641H19.0195C19.5625 10.6641 20 11.1016 20 11.6445C20 12.1875 19.5625 12.625 19.0195 12.625Z"
        fill="#FFFFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2619_306944">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
