<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.94758 2.23777C2.41364 2.81279 2.125 3.67913 2.125 4.83332V13.1667C2.125 14.3208 2.41364 15.1872 2.94758 15.7622C3.47373 16.3288 4.32746 16.7083 5.66667 16.7083H12.3333C13.6725 16.7083 14.5263 16.3288 15.0524 15.7622C15.5864 15.1872 15.875 14.3208 15.875 13.1667V4.83332C15.875 3.67913 15.5864 2.81279 15.0524 2.23777C14.5263 1.67114 13.6725 1.29166 12.3333 1.29166H5.66667C4.32746 1.29166 3.47373 1.67114 2.94758 2.23777ZM2.03159 1.38721C2.8596 0.495503 4.08921 0.0416565 5.66667 0.0416565H12.3333C13.9108 0.0416565 15.1404 0.495503 15.9684 1.38721C16.7886 2.27053 17.125 3.48751 17.125 4.83332V13.1667C17.125 14.5125 16.7886 15.7295 15.9684 16.6128C15.1404 17.5045 13.9108 17.9583 12.3333 17.9583H5.66667C4.08921 17.9583 2.8596 17.5045 2.03159 16.6128C1.21136 15.7295 0.875 14.5125 0.875 13.1667V4.83332C0.875 3.48751 1.21136 2.27053 2.03159 1.38721Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.084 2.125C11.4292 2.125 11.709 2.40482 11.709 2.75V4.41667C11.709 4.98816 12.1792 5.45833 12.7507 5.45833H14.4173C14.7625 5.45833 15.0423 5.73816 15.0423 6.08333C15.0423 6.42851 14.7625 6.70833 14.4173 6.70833H12.7507C11.4888 6.70833 10.459 5.67851 10.459 4.41667V2.75C10.459 2.40482 10.7388 2.125 11.084 2.125Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.04102 9.83331C5.04102 9.48814 5.32084 9.20831 5.66602 9.20831H8.99935C9.34453 9.20831 9.62435 9.48814 9.62435 9.83331C9.62435 10.1785 9.34453 10.4583 8.99935 10.4583H5.66602C5.32084 10.4583 5.04102 10.1785 5.04102 9.83331Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.04102 13.1667C5.04102 12.8215 5.32084 12.5417 5.66602 12.5417H12.3327C12.6779 12.5417 12.9577 12.8215 12.9577 13.1667C12.9577 13.5118 12.6779 13.7917 12.3327 13.7917H5.66602C5.32084 13.7917 5.04102 13.5118 5.04102 13.1667Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
