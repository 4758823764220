<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0127 1.29102C13.4301 1.29247 12.957 1.76611 12.957 2.34102V7.54102H15.182C15.7065 7.54102 16.0739 7.37772 16.3088 7.14282C16.5437 6.90793 16.707 6.54054 16.707 6.01602V3.99935C16.707 3.2574 16.4043 2.57977 15.9136 2.08145C15.4286 1.5972 14.753 1.29861 14.0127 1.29102ZM11.707 2.34102C11.707 1.0668 12.7493 0.0410156 14.0154 0.0410156H14.0211L14.0211 0.041042C15.0955 0.0508985 16.0848 0.484882 16.799 1.19907L16.802 1.20212L16.802 1.20213C17.5104 1.92034 17.957 2.90873 17.957 3.99935V6.01602C17.957 6.80816 17.7037 7.51577 17.1927 8.02671C16.6818 8.53765 15.9742 8.79102 15.182 8.79102H12.332C11.9869 8.79102 11.707 8.51119 11.707 8.16602V2.34102Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.922149 1.23357C1.5928 0.456948 2.6214 0.0410156 3.99935 0.0410156H13.9993C14.3445 0.0410156 14.6243 0.320838 14.6243 0.666016C14.6243 1.01119 14.3445 1.29102 13.9993 1.29102C13.4279 1.29102 12.9577 1.76119 12.9577 2.33268V16.4993C12.9577 17.7008 11.5856 18.3797 10.6268 17.6678L10.6248 17.6664L9.19325 16.5948C9.1214 16.5395 9.00653 16.5427 8.93297 16.6163L7.53298 18.0163C6.9639 18.5854 6.0348 18.5854 5.46572 18.0163L5.46439 18.015L4.08241 16.6246C4.08228 16.6245 4.08254 16.6248 4.08241 16.6246C3.99972 16.5423 3.88075 16.5383 3.79935 16.5993L2.37674 17.6642C2.37618 17.6646 2.37562 17.665 2.37506 17.6655C1.40991 18.3946 0.0410156 17.7022 0.0410156 16.4993V3.99935C0.0410156 2.99696 0.266293 1.99305 0.922149 1.23357ZM11.9591 1.29102H3.99935C2.8773 1.29102 2.23923 1.62092 1.86822 2.05055C1.48241 2.49732 1.29102 3.16007 1.29102 3.99935V16.4993C1.29102 16.6791 1.48717 16.7699 1.62192 16.6678L1.6248 16.6657L3.04935 15.5993C3.63448 15.1605 4.44901 15.2235 4.96629 15.7407L4.96763 15.7421L6.34961 17.1324C6.34948 17.1323 6.34973 17.1325 6.34961 17.1324C6.43058 17.213 6.5683 17.2132 6.64909 17.1324L8.04909 15.7324C8.55776 15.2237 9.37373 15.1595 9.95166 15.6011L11.3719 16.6642C11.3722 16.6644 11.3724 16.6645 11.3726 16.6647C11.5138 16.7687 11.7077 16.6643 11.7077 16.4993V2.33268C11.7077 1.95805 11.7985 1.60387 11.9591 1.29102Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.375 6.5C3.375 6.15482 3.65482 5.875 4 5.875H9C9.34518 5.875 9.625 6.15482 9.625 6.5C9.625 6.84518 9.34518 7.125 9 7.125H4C3.65482 7.125 3.375 6.84518 3.375 6.5Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 9.83203C4 9.48685 4.27982 9.20703 4.625 9.20703H8.375C8.72018 9.20703 9 9.48685 9 9.83203C9 10.1772 8.72018 10.457 8.375 10.457H4.625C4.27982 10.457 4 10.1772 4 9.83203Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
