<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      x="20.4004"
      y="20"
      width="20.4"
      height="3"
      rx="1.5"
      transform="rotate(-180 20.4004 20)"
      fill="#FAFAFA"
    />
    <rect
      opacity="0.4"
      x="15.5996"
      y="13"
      width="15.6"
      height="3"
      rx="1.5"
      transform="rotate(-180 15.5996 13)"
      fill="#FAFAFA"
    />
    <rect
      x="24"
      y="6"
      width="24"
      height="3"
      rx="1.5"
      transform="rotate(-180 24 6)"
      fill="#FAFAFA"
    />
  </svg>
</template>
