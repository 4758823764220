<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.94023 13.78C5.81356 13.78 5.68689 13.7333 5.58689 13.6333C5.39356 13.44 5.39356 13.12 5.58689 12.9267L9.93356 8.58001C10.2536 8.26001 10.2536 7.74001 9.93356 7.42001L5.58689 3.07335C5.39356 2.88001 5.39356 2.56001 5.58689 2.36668C5.78023 2.17335 6.10023 2.17335 6.29356 2.36668L10.6402 6.71335C10.9802 7.05335 11.1736 7.51335 11.1736 8.00001C11.1736 8.48668 10.9869 8.94668 10.6402 9.28668L6.29356 13.6333C6.19356 13.7267 6.06689 13.78 5.94023 13.78Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
