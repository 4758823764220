import {
  getMakerCoin,
  updateMakerCoin,
  getCallbackAddressStore,
} from "@/api/admin/request";
import store from "..";
import i18n from "@/locales";

const state = {
  listCoin: [],
  listCallbackAddress: [],
};

const getters = {
  getCallbackAddressStore(state) {
    return state.listCallbackAddress;
  },
};

const mutations = {
  handleUpdateListCoin(state, payload) {
    state.listCoin = payload;
  },

  setCallbackAddressStore(state, payload) {
    state.listCallbackAddress = payload;
  },
};

const actions = {
  async getListMakerCoinAction({ commit }, data) {
    const res = await getMakerCoin(data);
    try {
      if (res.data.status == 0) {
        commit("handleUpdateListCoin", res.data.data);
        commit("handleUpdateLoading", false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      store.commit("handleUpdateLoading", false);
    }
  },

  async updateListMakerCoinAction({ commit }, data) {
    const res = await updateMakerCoin(data);

    try {
      if (res.data.status == 0) {
        await store.dispatch("showAlert", {
          type: "success",
          message: i18n.global.t("requestSuccess"),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      store.commit("handleUpdateLoading", false);
    }
  },

  async handleGetCallbackAddressStore({ commit }, data) {
    const res = await getCallbackAddressStore(data);
    try {
      if (res.data.status == 0) {
        store.commit("setCallbackAddressStore", res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
