import {
  getWalletRequest,
  getMetaRequest,
  getPrice,
  createStoreWalletAddres,
  getStoreWalletAddres,
  getKeyWallet,
  getAmountTRX,
} from "@/api/wallet/request";
import store from "..";

const state = {
  listWallet: [],
  listNetwork: [],
  listCoin: [],
  listCurrentcy: [],
  listStoreWalletAddres: { list: [], pagination: {} },
  prices: {},
  keyWallet: {},
  isPublic: false,
  amountRTX: null,
};

const getters = {
  getlistNetwork(state) {
    return state.listNetwork;
  },
  getMasterWallet(state) {
    return state.listWallet.filter((item) => item.wallet_name != "BYBIT_TRX");
  },
  getListCoin(state) {
    return state.listCoin;
  },

  getPrices(state) {
    return state.prices;
  },

  getStoreWalletAddress(state) {
    // const newList = state.listStoreWalletAddres.list.filter(
    //   (item) => item.name_network == "trc20"
    // );
    return state.listStoreWalletAddres.list;

    // return newList;
  },
  getKeyWallet(state) {
    return state.keyWallet;
  },
  getAmountRTX(state) {
    return state.amountRTX;
  },
};

const mutations = {
  setListWallet(state, payload) {
    state.listWallet = payload;
  },

  setListNetwork(state, payload) {
    state.listNetwork = payload;
  },

  setListCoin(state, payload) {
    state.listCoin = payload;
  },

  setPrices(state, payload) {
    state.prices = payload;
  },

  setCurrentcy(state, payload) {
    state.listCurrentcy = payload;
  },

  setStoreWalletAddress(state, payload) {
    state.listStoreWalletAddres = payload;
  },

  setKeyWallet(state, payload) {
    state.keyWallet = payload;
  },
  setStatusAddTransfer(state, payload) {
    state.isPublic = payload;
  },
  setAmountRTX(state, payload) {
    state.amountRTX = payload;
  },
};

const actions = {
  async handleGetWalletAction({ commit }, data) {
    const res = await getWalletRequest(data);
    if (res.data.status == 0) {
      store.commit("setListWallet", res.data.data);
    }
  },

  async handleGetNetworkAction({ commit }, data) {
    const res = await getMetaRequest(data);
    if (res.data.status == 0) {
      store.commit("setListNetwork", res.data.data);
    }
  },

  async handleGetCoinAction({ commit }, data) {
    const res = await getMetaRequest(data);
    if (res.data.status == 0) {
      store.commit("setListCoin", res.data.data);
    }
  },

  async handleGetPriceAction({ commit }, data) {
    const res = await getPrice(data);
    try {
      if (res.data.code == 0) {
        store.commit("setPrices", res.data.data);
      }
    } catch (error) {
      console.log(error);
    }

    return res;
  },

  async handleGetCurrentcyAction({ commit }, data) {
    const res = await getMetaRequest(data);
    if (res.data) {
      store.commit("setCurrentcy", res.data.data);
    }
  },

  async handleCreateStoreWalletAddr({ commit }, data) {
    const res = await createStoreWalletAddres(data);
    return res.data;
  },

  async handleGetStoreWalletAddrAction({ commit }, data) {
    const res = await getStoreWalletAddres(data);
    if (res.data.status === 0) {
      store.commit("setStoreWalletAddress", {
        list: res.data.data,
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
    }
  },

  async handleGetKeyWalletAction({ commit }, data) {
    const res = await getKeyWallet(data);
    if (res.data.code === 0) {
      store.commit("setKeyWallet", res.data.data);
    }
  },

  async handleGetAmountRTXAction({ commit }, data) {
    const res = await getAmountTRX(data);
    if (res.data.code == 0) {
      store.commit("setAmountRTX", res.data.data);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
