import { AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const getCommissionDetailRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/log/commission${
      params ? `?${queryString.stringify(params)}` : ""
    }`,
    method: "GET",
  });
  return res;
};

export const getCommissionDetailRequest2 = async (params) => {
  const res = await AuthRequest({
    url: `/api/log/commission2${
        params ? `?${queryString.stringify(params)}` : ""
    }`,
    method: "GET",
  });
  return res;
};

export const updateMemoRequestSettlement = async (data) => {
  let idx = data.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/settlement-request/${idx}`,
    method: "PUT",
    data,
  });
  return res;
};
