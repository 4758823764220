<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <mask id="path-1-inside-1_3575_32950" fill="white">
      <path
        d="M4.667 2C3.194 2 2 3.1942 2 4.667C2 6.1396 3.194 7.3334 4.667 7.3334C6.1396 7.3334 7.3336 6.1398 7.3336 4.667C7.3336 3.1942 6.1396 2 4.667 2ZM4.667 6C3.9304 6 3.3334 5.403 3.3334 4.6666C3.3334 3.9302 3.9304 3.3332 4.667 3.3332C5.4034 3.3332 6.0004 3.9302 6.0004 4.6666C6.0004 5.403 5.4034 6 4.667 6Z"
      />
    </mask>
    <path
      d="M4.667 2C3.194 2 2 3.1942 2 4.667C2 6.1396 3.194 7.3334 4.667 7.3334C6.1396 7.3334 7.3336 6.1398 7.3336 4.667C7.3336 3.1942 6.1396 2 4.667 2ZM4.667 6C3.9304 6 3.3334 5.403 3.3334 4.6666C3.3334 3.9302 3.9304 3.3332 4.667 3.3332C5.4034 3.3332 6.0004 3.9302 6.0004 4.6666C6.0004 5.403 5.4034 6 4.667 6Z"
      fill="#FFFFFF"
    />
    <path
      d="M4.667 -3.12C0.366051 -3.12 -3.12 0.366752 -3.12 4.667H7.12C7.12 6.02165 6.02195 7.12 4.667 7.12V-3.12ZM-3.12 4.667C-3.12 8.9683 0.367301 12.4534 4.667 12.4534V2.2134C6.0207 2.2134 7.12 3.3109 7.12 4.667H-3.12ZM4.667 12.4534C8.96655 12.4534 12.4536 8.96825 12.4536 4.667H2.2136C2.2136 3.31135 3.31265 2.2134 4.667 2.2134V12.4534ZM12.4536 4.667C12.4536 0.367252 8.96805 -3.12 4.667 -3.12V7.12C3.31115 7.12 2.2136 6.02115 2.2136 4.667H12.4536ZM4.667 0.88C6.7576 0.88 8.4534 2.5748 8.4534 4.6666H-1.7866C-1.7866 8.2312 1.1032 11.12 4.667 11.12V0.88ZM8.4534 4.6666C8.4534 6.7584 6.7576 8.4532 4.667 8.4532V-1.7868C1.1032 -1.7868 -1.7866 1.102 -1.7866 4.6666H8.4534ZM4.667 8.4532C2.5757 8.4532 0.8804 6.7579 0.8804 4.6666H11.1204C11.1204 1.1025 8.2311 -1.7868 4.667 -1.7868V8.4532ZM0.8804 4.6666C0.8804 2.5753 2.5757 0.88 4.667 0.88V11.12C8.2311 11.12 11.1204 8.2307 11.1204 4.6666H0.8804Z"
      fill="#FFFFFF"
      mask="url(#path-1-inside-1_3575_32950)"
    />
    <mask id="path-3-inside-2_3575_32950" fill="white">
      <path d="M8.66602 2.66602H17.9994V3.99942H8.66602V2.66602Z" />
    </mask>
    <path
      d="M8.66602 2.66602H17.9994V3.99942H8.66602V2.66602Z"
      fill="#FFFFFF"
    />
    <path
      d="M8.66602 2.66602V-2.45398H3.54602V2.66602H8.66602ZM17.9994 2.66602H23.1194V-2.45398H17.9994V2.66602ZM17.9994 3.99942V9.11942H23.1194V3.99942H17.9994ZM8.66602 3.99942H3.54602V9.11942H8.66602V3.99942ZM8.66602 7.78602H17.9994V-2.45398H8.66602V7.78602ZM12.8794 2.66602V3.99942H23.1194V2.66602H12.8794ZM17.9994 -1.12058H8.66602V9.11942H17.9994V-1.12058ZM13.786 3.99942V2.66602H3.54602V3.99942H13.786Z"
      fill="#FFFFFF"
      mask="url(#path-3-inside-2_3575_32950)"
    />
    <mask id="path-5-inside-3_3575_32950" fill="white">
      <path d="M8.66602 5.33398H15.3326V6.66738H8.66602V5.33398Z" />
    </mask>
    <path
      d="M8.66602 5.33398H15.3326V6.66738H8.66602V5.33398Z"
      fill="#FFFFFF"
    />
    <path
      d="M8.66602 5.33398V0.213984H3.54602V5.33398H8.66602ZM15.3326 5.33398H20.4526V0.213984H15.3326V5.33398ZM15.3326 6.66738V11.7874H20.4526V6.66738H15.3326ZM8.66602 6.66738H3.54602V11.7874H8.66602V6.66738ZM8.66602 10.454H15.3326V0.213984H8.66602V10.454ZM10.2126 5.33398V6.66738H20.4526V5.33398H10.2126ZM15.3326 1.54738H8.66602V11.7874H15.3326V1.54738ZM13.786 6.66738V5.33398H3.54602V6.66738H13.786Z"
      fill="#FFFFFF"
      mask="url(#path-5-inside-3_3575_32950)"
    />
    <path
      d="M4.667 8.66602C3.194 8.66602 2 9.86002 2 11.3324C2 12.805 3.194 13.999 4.667 13.999C6.1396 13.999 7.3336 12.805 7.3336 11.3324C7.3336 9.86002 6.1396 8.66602 4.667 8.66602ZM4.667 12.6656C3.9304 12.6656 3.3334 12.0694 3.3334 11.3324C3.3334 10.5966 3.9304 9.99922 4.667 9.99922C5.4034 9.99922 6.0004 10.5966 6.0004 11.3324C6.0002 12.0694 5.4034 12.6656 4.667 12.6656Z"
      fill="#FFFFFF"
    />
    <path d="M8.66602 9.33398H17.9994V10.667H8.66602V9.33398Z" fill="#FFFFFF" />
    <path d="M8.66602 12H15.3326V13.3334H8.66602V12Z" fill="#FFFFFF" />
    <path d="M8.66602 16H17.9994V17.3334H8.66602V16Z" fill="#FFFFFF" />
    <path
      d="M4.667 16.6672C5.4034 16.6672 6.0004 17.2648 6.0004 18.0006H7.3338C7.3338 16.528 6.1398 15.334 4.6672 15.334C3.194 15.334 2 16.528 2 18.0006H3.3334C3.3334 17.2648 3.9304 16.6672 4.667 16.6672Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
