import { getListBankRequest } from "@/api/getBank/request";
// import store from "@/store";
// import { computed } from "vue";

const state = {
  listBankInfo: [],
};

const getters = {
  getBankInfo: (state) => {
    return state.listBankInfo;
  },
};

const mutations = {
  handleUpdateBankInfo(state, payload) {
    state.listBankInfo = payload;
  },
};

const actions = {
  async getBankActive({ commit }, data) {
    const res = await getListBankRequest(data);
    if (res?.data) {
      commit("handleUpdateBankInfo", res.data.data);
    } else {
      console.log(res?.message);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
