import store from "@/store";
import moment from "moment";
import { deleteCookie } from "./cookie";

export const formatDate = (dateString) => {
  return moment(dateString, "DD.MM.YYYY").format("YY.MM.DD");
};
export const convertMoney = (money) => {
  return parseFloat(money).toFixed(2).toLocaleString("en");
};

export const convertTime = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  if (type) {
    return `${moment(timestamp * 1000).format("YY.MM.DD hh:mm:ss A")}`;
  } else {
    return `${formattedDate} ${formattedTime}`;
  }
};

export const TimeConvert = (timestamp) => {
  return `${moment(timestamp).format("YY.MM.DD HH:MM:SS")}`;
};

export const handleLogout = () => {
  deleteCookie("auth");
  localStorage.clear();
  sessionStorage.clear();
  //로그인 매장 정보 초기화
  store.commit("handleAuth");
  //로그인 매장 수수료 초기화
  store.commit("handleUpdateCommission");
  //입금 목록 초기화
  store.commit("handleResetPaymentList");
  //출금 목록 초기화
  store.commit("handleResetWithdrawList");
  //수수료 목록 초기화
  store.commit("handleUpdateCommissionDetail");
  //매장 목록 초기화
  store.commit("handleStoreStore");
  //유저 목록 초기화
  store.commit("handleUpdateListUser");
};
