import { AuthRequest, SSRRequest, SSRBrokenRequest } from "@/api/axios";
import queryString from "query-string";

export const getWalletRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/master-wallet-code${
      params ? `?${queryString.stringify(params)}` : ""
    }`,
    method: "GET",
  });
  return res;
};

export const getMetaRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/meta-code${
      params ? `?${queryString.stringify({ ...params })}` : ""
    }`,
    method: "GET",
  });
  return res;
};

export const getPrice = async (data) => {
  // data.userIdx = 2;
  // data.storeCode = "AA";
  const res = await SSRBrokenRequest({
    url: "/transaction/check/price",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const getAmountTRX = async (data) => {
  // data.userIdx = 2;
  // data.storeCode = "AA";
  const res = await SSRBrokenRequest({
    url: "/transaction/check/fee",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};

export const createStoreWalletAddres = async (data) => {
  const res = await AuthRequest({
    url: "/api/store-wallet-addr",
    method: "POST",
    data,
  });
  return res;
};

export const getStoreWalletAddres = async (params) => {
  const res = await AuthRequest({
    url: `/api/store-wallet-addr${
      params ? `?${queryString.stringify(params)}` : ""
    }`,
    method: "GET",
  });
  return res;
};

export const getKeyWallet = async (data) => {
  data.userIdx = 2;
  data.storeCode = "AA";
  const res = await SSRBrokenRequest({
    url: "/wallet/create",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return res;
};
