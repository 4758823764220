<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M6.28268 18.041C6.71602 18.6243 7.34935 18.9577 7.99935 18.9577C8.64935 18.9577 9.28268 18.6243 9.71602 18.041L10.5577 16.916C10.7327 16.6827 10.9743 16.5493 11.2243 16.5327C11.4827 16.5077 11.7327 16.6243 11.9327 16.841C13.141 18.1243 14.0577 18.0243 14.4993 17.8493C14.9493 17.666 15.7077 17.091 15.7077 15.2493V7.29518C15.7077 6.9477 15.426 6.66602 15.0785 6.66602C14.731 6.66602 14.4493 6.9477 14.4493 7.29518V15.2493C14.4493 16.116 14.2327 16.5993 14.0327 16.6827C13.8827 16.741 13.4743 16.6577 12.841 15.9827C12.391 15.4993 11.7743 15.241 11.1577 15.2743C10.5327 15.3077 9.95768 15.6327 9.55768 16.1577L8.71602 17.2827C8.30768 17.8243 7.68268 17.8243 7.27435 17.2827L6.43268 16.1577C6.04102 15.6327 5.45768 15.3077 4.84102 15.2743H4.73268C4.15768 15.2743 3.59935 15.5244 3.14935 15.9827C2.51602 16.6577 2.09935 16.741 1.95768 16.6827C1.75768 16.6077 1.54102 16.1243 1.54102 15.2493V5.86602C1.54102 2.85768 2.06602 2.29102 4.84935 2.29102H9.45768C9.80286 2.29102 10.0827 2.01119 10.0827 1.66602C10.0827 1.32084 9.80286 1.04102 9.45768 1.04102H4.84935C1.35768 1.04102 0.291016 2.16602 0.291016 5.86602V15.2493C0.291016 17.0993 1.04935 17.6744 1.49935 17.8493C1.94102 18.0243 2.86602 18.1243 4.06602 16.841L3.60768 16.416L4.08268 16.841C4.28268 16.6327 4.53268 16.5243 4.78268 16.5327C5.03268 16.5493 5.26602 16.6827 5.44102 16.916L6.28268 18.041Z"
      fill="#FFFFFF"
    />
    <path
      d="M11.5247 9.79102H6.94141C6.59974 9.79102 6.31641 9.50768 6.31641 9.16602C6.31641 8.82435 6.59974 8.54102 6.94141 8.54102H11.5247C11.8664 8.54102 12.1497 8.82435 12.1497 9.16602C12.1497 9.50768 11.8664 9.79102 11.5247 9.79102Z"
      fill="#FFFFFF"
    />
    <path
      d="M11.5247 6.45898H6.94141C6.59974 6.45898 6.31641 6.17565 6.31641 5.83398C6.31641 5.49232 6.59974 5.20898 6.94141 5.20898H11.5247C11.8664 5.20898 12.1497 5.49232 12.1497 5.83398C12.1497 6.17565 11.8664 6.45898 11.5247 6.45898Z"
      fill="#FFFFFF"
    />
    <path
      d="M4.48177 6.66667C4.02344 6.66667 3.64844 6.29167 3.64844 5.83333C3.64844 5.375 4.02344 5 4.48177 5C4.9401 5 5.3151 5.375 5.3151 5.83333C5.3151 6.29167 4.9401 6.66667 4.48177 6.66667Z"
      fill="#FFFFFF"
    />
    <path
      d="M4.48177 10.0007C4.02344 10.0007 3.64844 9.62565 3.64844 9.16732C3.64844 8.70898 4.02344 8.33398 4.48177 8.33398C4.9401 8.33398 5.3151 8.70898 5.3151 9.16732C5.3151 9.62565 4.9401 10.0007 4.48177 10.0007Z"
      fill="#FFFFFF"
    />
    <path
      d="M16.9564 4.58333C16.6147 4.58333 16.3314 4.3 16.3314 3.95833V1.25H13.623C13.2814 1.25 12.998 0.966667 12.998 0.625C12.998 0.283333 13.2814 0 13.623 0H16.9564C17.298 0 17.5814 0.283333 17.5814 0.625V3.95833C17.5814 4.3 17.298 4.58333 16.9564 4.58333Z"
      fill="#FFFFFF"
    />
    <path
      d="M12.7889 5.41654C12.6306 5.41654 12.4723 5.3582 12.3473 5.2332C12.1056 4.99153 12.1056 4.59154 12.3473 4.34987L16.5139 0.183203C16.7556 -0.0584635 17.1556 -0.0584635 17.3973 0.183203C17.6389 0.42487 17.6389 0.824868 17.3973 1.06653L13.2306 5.2332C13.1056 5.3582 12.9473 5.41654 12.7889 5.41654Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
