<template>
  <div class="w-100 h-100 position-relative">
    <div
      @wheel.prevent
      v-if="isLoading"
      class="d-flex justify-center align-center live-casino w-100 h-100"
    >
      <div class="loading w-100 d-flex justify-center">
        <Loading />
      </div>
    </div>
    <div @wheel.prevent v-if="isLoading" class="w-100 h-100 live-opacity"></div>
    <router-view />
    <Toast />
  </div>
  <!-- <router-view />
  <Toast /> -->
</template>

<script setup>
import Toast from "@/components/ziupay/Toast";
import Loading from "@/components/ziupay/Loading";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isLoading = computed(() => store.state.auth.loading);
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrap {
  max-width: 1620px;
  margin: 0 auto;
}

.wrap-custom {
  max-width: 1920px;
  margin: 0 auto;
}

.live-opacity {
  opacity: 0.5;
  background-color: #1c2529;
  z-index: 88;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.live-casino {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn_primary {
  background: var(--cms-primary-color);
  border: 1px solid var(--cms-primary-color);
  border-radius: 8px;
  color: #fff;
  padding: 5px 30px;
  height: 36px;

  &:hover {
    background: #fff;
    color: var(--cms-primary-color);
  }
}
.text-error {
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.statusCode {
  height: 36px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: start;

  max-width: 150px;

  @media (max-width: 768px) {
    // width: 120px;
    justify-content: end;
    height: max-content;
  }
}

.statusCode2 {
  color: #df4949;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #df4949;
    margin-right: 10px;
  }
}

.statusCode1 {
  color: #33b028;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #33b028;
    margin-right: 10px;
  }
}

.statusCode0 {
  color: #ea7502;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ea7502;
    margin-right: 10px;
  }
}

.completion {
  color: #28ac1c;
  display: flex;
  align-items: center;
  background: rgba(40, 172, 28, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #28ac1c;
    margin-right: 10px;
  }
}

.cancel {
  color: #df4949;
  display: flex;
  align-items: center;
  background: rgba(223, 73, 73, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #df4949;
    margin-right: 10px;
  }
}

.txStatus0 {
  color: #f7c604;
  display: flex;
  align-items: center;
  background: rgba(247, 198, 4, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f7c604;
    margin-right: 10px;
  }
}

.txStatus1 {
  color: #28ac1c;
  display: flex;
  align-items: center;
  background: rgba(40, 172, 28, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #28ac1c;
    margin-right: 10px;
  }
}

.txStatus2 {
  color: #28ac1c;
  display: flex;
  align-items: center;
  background: rgba(40, 172, 28, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #28ac1c;
    margin-right: 10px;
  }
}

.txStatus3,
.txStatus4,
.txStatus5 {
  color: #df4949;
  display: flex;
  align-items: center;
  background: rgba(223, 73, 73, 0.1);
  border-radius: 16px;
  padding: 10px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #df4949;
    margin-right: 10px;
  }
}

#search {
  position: relative;
  z-index: 2;
}

.title_custom {
  padding: 16px 40px;
  background: #fff;
  box-shadow: 0px 0px 0.5px 0px rgba(0, 0, 0, 0.18),
    0px 3px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  min-height: 120px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: max-content;
  }
}

:root {
  --cms-primary-color: #56b8ff;
  --cms-danger-color: #dc3545;
  --cms-primary-text-color: #464255;
}

@media (max-width: 992px) {
  .wrap_pagination {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .table_mobile {
    .btn_select {
      max-width: 160px !important;
    }
  }
}

@media (max-width: 475px) {
  .pagination {
    font-size: 12px;
  }
}
</style>
