<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6668 7.16732H11.3335C9.72016 7.16732 8.8335 6.28065 8.8335 4.66732V3.33398C8.8335 1.72065 9.72016 0.833984 11.3335 0.833984H12.6668C14.2802 0.833984 15.1668 1.72065 15.1668 3.33398V4.66732C15.1668 6.28065 14.2802 7.16732 12.6668 7.16732ZM11.3335 1.83398C10.2802 1.83398 9.8335 2.28065 9.8335 3.33398V4.66732C9.8335 5.72065 10.2802 6.16732 11.3335 6.16732H12.6668C13.7202 6.16732 14.1668 5.72065 14.1668 4.66732V3.33398C14.1668 2.28065 13.7202 1.83398 12.6668 1.83398H11.3335Z"
      fill="white"
    />
    <path
      d="M4.66683 15.1673H3.3335C1.72016 15.1673 0.833496 14.2807 0.833496 12.6673V11.334C0.833496 9.72065 1.72016 8.83398 3.3335 8.83398H4.66683C6.28016 8.83398 7.16683 9.72065 7.16683 11.334V12.6673C7.16683 14.2807 6.28016 15.1673 4.66683 15.1673ZM3.3335 9.83398C2.28016 9.83398 1.8335 10.2807 1.8335 11.334V12.6673C1.8335 13.7207 2.28016 14.1673 3.3335 14.1673H4.66683C5.72016 14.1673 6.16683 13.7207 6.16683 12.6673V11.334C6.16683 10.2807 5.72016 9.83398 4.66683 9.83398H3.3335Z"
      fill="white"
    />
    <path
      d="M4.00016 7.16732C2.2535 7.16732 0.833496 5.74732 0.833496 4.00065C0.833496 2.25398 2.2535 0.833984 4.00016 0.833984C5.74683 0.833984 7.16683 2.25398 7.16683 4.00065C7.16683 5.74732 5.74683 7.16732 4.00016 7.16732ZM4.00016 1.83398C2.80683 1.83398 1.8335 2.80732 1.8335 4.00065C1.8335 5.19398 2.80683 6.16732 4.00016 6.16732C5.1935 6.16732 6.16683 5.19398 6.16683 4.00065C6.16683 2.80732 5.1935 1.83398 4.00016 1.83398Z"
      fill="white"
    />
    <path
      d="M12.0002 15.1673C10.2535 15.1673 8.8335 13.7473 8.8335 12.0007C8.8335 10.254 10.2535 8.83398 12.0002 8.83398C13.7468 8.83398 15.1668 10.254 15.1668 12.0007C15.1668 13.7473 13.7468 15.1673 12.0002 15.1673ZM12.0002 9.83398C10.8068 9.83398 9.8335 10.8073 9.8335 12.0007C9.8335 13.194 10.8068 14.1673 12.0002 14.1673C13.1935 14.1673 14.1668 13.194 14.1668 12.0007C14.1668 10.8073 13.1935 9.83398 12.0002 9.83398Z"
      fill="white"
    />
  </svg>
</template>
