import {
  IconDashboard,
  IconUser,
  IconMypage,
  IconPayment,
  IconSettlement,
  IconSetting,
  IconManager,
  IconAdminCallback,
  IconAdminManager,
  IconAdminWalletAddress,
  IconCommissionDetail,
  IconPaymentHistory,
  IconSettlementDetail,
  IconSettlementLowerStore,
  IconStoreList,
  IconUnapprovedPayment,
  IconUnapprovedWithdraw,
  IconUserList,
  IconBlockUserList,
  IconDeleteUserList,
  IconWithdrawHistory,
} from "@/assets/ziupay/svg/Menu";

const getTitle = () =>
{
  if(process.env.VUE_APP_SERVICE_TYPE == 'ZIU PAY')
  {
    return "Ziupay";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SUN PAY')
  {
    return "SUN PAY";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SIGMA PAY')
  {
    return "SIGMA PAY";
  }
}

const getFavicon = () =>
{
  if(process.env.VUE_APP_SERVICE_TYPE == 'ZIU PAY')
  {
    return "ZIUPAY.ico";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SUN PAY')
  {
    return "PCXPAY.ico"
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SIGMA PAY')
  {
    return "SIGMAPAY.ico"
  }
}

const paths = {
  Ziupay: [
    {
      path: "/",
      name: "MainLayout",
      component: () => import("@/layout/MainLayout/ziupay"),
      children: [
        // {
        //   path: "/view/dashboard",
        //   name: "dashboard",
        //   params: { type: "year", coin: "TRC20" },
        //   component: () => import("@/views/ziupay/Dashboard"),
        //   icon: IconDashboard,
        // },
        {
          path: "/mypage",
          name: "myPage",
          component: () => import("@/views/ziupay/MyPage"),
          icon: IconMypage,
        },
        {
          path: "/payment",
          name: "depositManager",
          icon: IconPayment,
          children: [
            {
              path: "/payment/unapproved_payment_history",
              name: "outstandingPaymentHistory",
              params: { page: 1, limit: 100, allow_status: 0, tx_type: 0 },
              icon: IconUnapprovedPayment,
              parentName: "paymentManager",
              component: () =>
                import("@/views/ziupay/PaymentManager/OutstandingHistory"),
            },
            {
              path: "/payment/payment_history",
              name: "paymentHistory",
              parentName: "paymentManager",
              params: { page: 1, limit: 100, allow_status: 1, tx_type: 0 },
              icon: IconPaymentHistory,
              component: () =>
                  import("@/views/ziupay/PaymentManager/PaymentHistory"),
            },
            {
              path: "/payment/payment_cancel",
              name: "depositCancel",
              parentName: "paymentManager",
              params: { page: 1, limit: 100, allow_status: 2, tx_type: 0 },
              icon: IconPaymentHistory,
              component: () =>
                  import("@/views/ziupay/PaymentManager/PaymentCancel"),
            },
            // {
            //   path: "/payment/payment_detail_by_store",
            //   name: "paymentDetailByStore",
            //   parentName: "paymentManager",
            //   component: () =>
            //     import("@/views/ziupay/PaymentManager/PaymentDetail"),
            // },
          ],
        },
        {
          path: "/payment",
          name: "withdrawManager",
          icon: IconPayment,
          children: [
            {
              path: "/payment/unapproved_withdrawal_history",
              name: "unapprovedWithdrawalHistory",
              params: { page: 1, limit: 100, allow_status: 0, tx_type: 1 },
              icon: IconUnapprovedWithdraw,
              parentName: "paymentManager",
              component: () =>
                  import(
                      "@/views/ziupay/PaymentManager/UnapprovedWithdrawalList"
                      ),
            },
            {
              path: "/payment/withdrawal_history",
              name: "withdrawalHistory",
              parentName: "paymentManager",
              params: { page: 1, limit: 100, allow_status: 1, tx_type: 1 },
              icon: IconWithdrawHistory,
              component: () =>
                  import("@/views/ziupay/PaymentManager/WithdrawalHistory"),
            },
            {
              path: "/payment/withdrawal_cancel",
              name: "withdrawCancel",
              parentName: "paymentManager",
              params: { page: 1, limit: 100, allow_status: 2, tx_type: 1 },
              icon: IconWithdrawHistory,
              component: () =>
                  import("@/views/ziupay/PaymentManager/WithdrawalCancel"),
            },
          ],
        },
        {
          path: "/settlement",
          name: "settlementManager",
          icon: IconSettlement,
          children: [
            // {
            //   path: "/detail_commission_settlement",
            //   name: "commissionDetail",
            //   params: { page: 1, limit: 100 },
            //   parentName: "settlementManager",
            //   icon: IconCommissionDetail,
            //   component: () =>
            //       import("@/views/ziupay/SettlementManager/commissionHistory"),
            // },
            {
              path: "/detail_commission_settlement",
              name: "commissionDetail",
              params: { page: 1, limit: 100 },
              parentName: "settlementManager",
              icon: IconCommissionDetail,
              component: () =>
                  import("@/views/ziupay/SettlementManager/commissionHistory2"),
            },
            {
              path: "/detail_settlement",
              name: "settlementDetail",
              params: { page: 1, limit: 100 },
              parentName: "settlementManager",
              icon: IconSettlementDetail,
              component: () =>
                import(
                  "@/views/ziupay/SettlementManager/IntegrateSettlementDetail"
                ),
            },
            // {
            //   path: "/commission_settlement_history_by_store",
            //   name: "commisstionHistoryByStore",
            //   parentName: "settlementManager",
            //   icon: IconSettlementLowerStore,
            //   component: () =>
            //     import(
            //       "@/views/ziupay/SettlementManager/CommissionHistoryByStore"
            //     ),
            // },
          ],
        },
        {
          path: "/view/login",
          name: "Login",
          component: () => import("@/views/ziupay/Login"),
        },
        {
          path: "/affiliate",
          name: "affiliate",
          icon: IconUser,
          children: [
            {
              path: "/affiliate/store",
              name: "affiliateStore",
              parentName: "affiliate",
              params: { page: 1, limit: 100 },
              icon: IconStoreList,
              component: () => import("@/views/ziupay/Affiliate/Store"),
            },
            {
              path: "/affiliate/user",
              name: "userList",
              parentName: "affiliate",
              params: { page: 1, limit: 100 },
              icon: IconUserList,
              component: () => import("@/views/ziupay/Affiliate/User"),
            },
            {
              path: "/affiliate/blocked_user",
              name: "blockedUserList",
              parentName: "affiliate",
              params: { page: 1, limit: 100 },
              icon: IconBlockUserList,
              component: () => import("@/views/ziupay/Affiliate/BlockedUser"),
            },
            {
              path: "/affiliate/deleted_user",
              name: "deletedUserList",
              parentName: "affiliate",
              params: { page: 1, limit: 100 },
              icon: IconDeleteUserList,
              component: () => import("@/views/ziupay/Affiliate/DeletedUser"),
            },
          ],
        },
        // {
        //   path: "/setting",
        //   name: "administratorMenu",
        //   icon: IconSetting,
        //   children: [
        //     {
        //       path: "/setting/management",
        //       name: "managementMenu",
        //       parentName: "administratorMenu",
        //       icon: IconAdminManager,
        //       component: () =>
        //         import("@/views/ziupay/AdminMenu/ManagementMenu"),
        //     },
        //     {
        //       path: "/setting/callback_address",
        //       name: "callback_address_for_store",
        //       parentName: "administratorMenu",
        //       icon: IconAdminCallback,
        //       component: () =>
        //         import("@/views/ziupay/AdminMenu/CallbackAddress"),
        //     },
        //     {
        //       path: "/setting/wallet_address_by_store",
        //       name: "wallet_address_by_store",
        //       parentName: "administratorMenu",
        //       icon: IconAdminWalletAddress,
        //       component: () =>
        //         import("@/views/ziupay/AdminMenu/WalletAddressByStore"),
        //     },
        //   ],
        // },
        // {
        //   path: "/manager",
        //   name: "manageDepositWithdrawMethod",
        //   icon: IconManager,
        //   component: () => import("@/views/ziupay/Manager"),
        // },
      ],
      meta: {
        title: getTitle(),
        icon: getFavicon(),
      },
    },

    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
};

export { paths };
