import axios from "axios";
import { BASE_API_URL, BASE_API_URL2 } from "@/const";

const SSRRequest = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
  baseURL: BASE_API_URL,
  timeout: 60000,
});

const SSRBrokenRequest = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
  baseURL: BASE_API_URL2,
  timeout: 60000,
});

const AuthRequest = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  baseURL: BASE_API_URL,
  timeout: 60000,
});

AuthRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Access-Token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AuthRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Xử lý lỗi phản hồi
    console.log(error);
    if (error.response) {
      // Nếu có phản hồi từ máy chủ, nhưng có lỗi (ví dụ: mã trạng thái không thành công)
      console.log(
        "Server responded with a non-success status:",
        error.response.status
      );
    } else if (error.request) {
      // Nếu yêu cầu được gửi đi nhưng không nhận được phản hồi (ví dụ: mất kết nối)
      console.log("No response received from server.");
    } else {
      // Nếu có lỗi xảy ra trong quá trình thiết lập yêu cầu hoặc xử lý phản hồi
      console.log(
        "An error occurred while sending or processing the request:",
        error.message
      );
    }
    throw error; // Ném lỗi để được xử lý ở các tầng cao hơn của ứng dụng
  }
);

SSRRequest.interceptors.response.use(
  (res) => {
    if (
      (res.data?.is_logined == true ||
        (res.data?.is_success == true && res.data?.error_code == 0)) &&
      res.config?.$useStore
    ) {
      res.config.$useStore(res.data, res);
    }

    return res.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }

    return Promise.reject(error.response.data);
  }
);

export { SSRRequest, AuthRequest, SSRBrokenRequest };
