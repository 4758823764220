<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 11.4238C2.34518 11.4238 2.625 11.7037 2.625 12.0488C2.625 13.016 3.54361 13.8738 4.98335 13.8738C6.412 13.8738 7.33335 13.0099 7.33335 12.0488C7.33335 11.7037 7.61318 11.4238 7.95835 11.4238C8.30353 11.4238 8.58335 11.7037 8.58335 12.0488V13.7405C8.58335 15.5977 6.88591 16.9572 4.98335 16.9572C3.08319 16.9572 1.375 15.5998 1.375 13.7405V12.0488C1.375 11.7037 1.65482 11.4238 2 11.4238ZM2.83629 14.5452C3.19491 15.2047 3.98226 15.7072 4.98335 15.7072C5.98501 15.7072 6.7701 15.2035 7.1257 14.5415C6.50936 14.9181 5.75584 15.1238 4.98335 15.1238C4.20887 15.1238 3.45383 14.9201 2.83629 14.5452Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.96519 7.79883C4.2832 7.79883 3.69031 8.03256 3.26896 8.40205L3.26845 8.4025C2.85176 8.7671 2.61523 9.24448 2.61523 9.75714C2.61523 10.0525 2.69679 10.3245 2.84107 10.566C3.19728 11.1429 3.97879 11.5821 4.97353 11.5821C5.97228 11.5821 6.7508 11.1485 7.10525 10.5673C7.25002 10.3254 7.33188 10.053 7.33188 9.75714C7.33188 9.24297 7.09454 8.7632 6.67343 8.41228L6.66555 8.40571L6.6656 8.40564C6.23913 8.03822 5.64278 7.79883 4.96519 7.79883ZM2.44481 7.46223C3.10679 6.88172 3.99718 6.54883 4.96519 6.54883C5.9357 6.54883 6.82115 6.89138 7.47762 7.45531C8.15423 8.02094 8.58188 8.83977 8.58188 9.75714C8.58188 10.294 8.43077 10.7876 8.17614 11.212L8.1742 11.2153L8.17419 11.2153C7.54485 12.2499 6.30719 12.8321 4.97353 12.8321C3.63656 12.8321 2.40231 12.2394 1.77438 11.2177L1.77089 11.212L1.77092 11.212C1.51627 10.7876 1.36523 10.2939 1.36523 9.75714C1.36523 8.83647 1.79534 8.03051 2.44532 7.46178L2.85688 7.93214L2.44481 7.46223Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.98335 7.79102C3.59304 7.79102 2.625 8.74921 2.625 9.75767V12.0493C2.625 13.0084 3.55225 13.8743 4.98335 13.8743C6.41352 13.8743 7.33335 13.0175 7.33335 12.0493V9.75767C7.33335 9.24348 7.0956 8.75902 6.68196 8.39628C6.25561 8.02973 5.65998 7.79102 4.98335 7.79102ZM1.375 9.75767C1.375 7.89946 3.07366 6.54102 4.98335 6.54102C5.95576 6.54102 6.84279 6.88491 7.49965 7.45082L7.50328 7.45395L7.50327 7.45397C8.15518 8.02438 8.58335 8.83928 8.58335 9.75767V12.0493C8.58335 13.9478 6.83651 15.1243 4.98335 15.1243C3.13111 15.1243 1.375 13.9403 1.375 12.0493V9.75767Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7662 6.91602C14.467 6.91602 14.2038 7.03203 14.014 7.22701L14.0028 7.23823C13.7811 7.45465 13.6572 7.7628 13.6884 8.09008L13.6891 8.09743L13.6891 8.09743C13.7332 8.62659 14.242 9.08266 14.8662 9.08266H16.4852C16.6199 9.07397 16.7079 8.96863 16.7079 8.8577V7.14098C16.7079 7.03004 16.6199 6.92471 16.4852 6.91602H14.7662ZM13.124 6.34931C13.5503 5.91447 14.1348 5.66602 14.7662 5.66602H16.4996C16.507 5.66602 16.5144 5.66615 16.5219 5.66641C17.316 5.69478 17.9579 6.33742 17.9579 7.14098V8.8577C17.9579 9.66126 17.316 10.3039 16.5219 10.3323C16.5144 10.3325 16.507 10.3327 16.4996 10.3327H14.8662C13.6916 10.3327 12.5515 9.47374 12.4437 8.20492C12.3764 7.48643 12.65 6.81522 13.124 6.34931Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.83268 1.54102C4.62914 1.54102 4.436 1.55555 4.25272 1.58375L4.23551 1.58615C3.31274 1.70195 2.58734 2.09702 2.09064 2.67977C1.59266 3.26402 1.29102 4.07674 1.29102 5.08268V6.74935C1.29102 7.09453 1.01119 7.37435 0.666016 7.37435C0.320838 7.37435 0.0410156 7.09453 0.0410156 6.74935V5.08268C0.0410156 3.82196 0.422708 2.70967 1.13931 1.86892C1.85549 1.02867 2.87278 0.499267 4.07129 0.34696C4.31862 0.30938 4.57259 0.291016 4.83268 0.291016H12.3327C12.5574 0.291016 12.8 0.299143 13.0448 0.338607C14.2535 0.482469 15.2823 1.00844 16.0079 1.84935C16.7354 2.69237 17.1243 3.8121 17.1243 5.08268V6.29103C17.1243 6.6362 16.8445 6.91603 16.4993 6.91603H14.766C14.4668 6.91603 14.2036 7.03204 14.0138 7.22702L14.0026 7.23824C13.7809 7.45466 13.657 7.76281 13.6882 8.09009L13.6889 8.09744L13.6889 8.09744C13.733 8.6266 14.2418 9.08267 14.866 9.08267H16.4993C16.8445 9.08267 17.1243 9.36249 17.1243 9.70767V10.916C17.1243 12.308 16.6576 13.5166 15.7955 14.3788C14.9333 15.2409 13.7247 15.7077 12.3327 15.7077H10.2493C9.90417 15.7077 9.62435 15.4279 9.62435 15.0827C9.62435 14.7375 9.90417 14.4577 10.2493 14.4577H12.3327C13.4407 14.4577 14.3154 14.0911 14.9116 13.4949C15.5078 12.8987 15.8743 12.024 15.8743 10.916V10.3327H14.866C13.6914 10.3327 12.5513 9.47374 12.4435 8.20492C12.3762 7.48643 12.6498 6.81522 13.1238 6.34931C13.5501 5.91447 14.1346 5.66603 14.766 5.66603H15.8743V5.08268C15.8743 4.06993 15.5674 3.25215 15.0616 2.66601C14.5569 2.0812 13.8203 1.68719 12.8855 1.57849C12.8753 1.5773 12.8651 1.57586 12.8549 1.57417C12.705 1.54918 12.5365 1.54102 12.3327 1.54102H4.83268Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
