<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00065 1.29166C6.04464 1.29166 4.45898 2.87731 4.45898 4.83332C4.45898 6.78933 6.04464 8.37499 8.00065 8.37499C9.95666 8.37499 11.5423 6.78933 11.5423 4.83332C11.5423 2.87731 9.95666 1.29166 8.00065 1.29166ZM3.20898 4.83332C3.20898 2.18696 5.35429 0.0416565 8.00065 0.0416565C10.647 0.0416565 12.7923 2.18696 12.7923 4.83332C12.7923 7.47969 10.647 9.62499 8.00065 9.62499C5.35429 9.62499 3.20898 7.47969 3.20898 4.83332Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.216797 17.3333C0.216797 13.6493 3.83107 10.875 8.00013 10.875C12.1692 10.875 15.7835 13.6493 15.7835 17.3333C15.7835 17.6785 15.5036 17.9583 15.1585 17.9583C14.8133 17.9583 14.5335 17.6785 14.5335 17.3333C14.5335 14.5674 11.7311 12.125 8.00013 12.125C4.26919 12.125 1.4668 14.5674 1.4668 17.3333C1.4668 17.6785 1.18697 17.9583 0.841797 17.9583C0.496619 17.9583 0.216797 17.6785 0.216797 17.3333Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
