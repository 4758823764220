import { getCommissionDetailRequest, getCommissionDetailRequest2 } from "@/api/settlement/request";
import store from "..";

const state = {
  list: [],
  detail: undefined,
  detailList: [],
  commissionDetail: { list: [], pagination: {} },
  itemEditMemo: {},
};

const getters = {
  getSettlementDetail: (state) => {
    return state.detail;
  },

  getItemEditMemo: (state) => {
    return state.itemEditMemo;
  },
};

const mutations = {
  handleGetDetail(state, payload) {
    state.detail = payload;
  },
  handleUpdateCommissionDetail(state, payload) {
    state.commissionDetail = payload;
  },
  setItemEditMemo(state, payload) {
    state.itemEditMemo = payload;
  },
};

const actions = {
  async getCommissionDetailAction({ commit }, data) {
    const res = await getCommissionDetailRequest(data);
    try {
      if (res.data.status == 0) {
        store.commit("handleUpdateCommissionDetail", {
          list: res.data.data,
          pagination: {
            limit: res.data.limit,
            page: res.data.page,
            total: res.data.total,
          },
        });
      }
      store.commit("handleUpdateLoading", false);
    } catch (error) {
      console.log(error);
      store.commit("handleUpdateLoading", false);
    } finally {
      store.commit("handleUpdateLoading", false);
    }
  },
  async getCommissionDetailAction2({ commit }, data) {
    const res = await getCommissionDetailRequest2(data);
    try {
      if (res.data.status == 0) {
        store.commit("handleUpdateCommissionDetail", {
          list: res.data.data,
          pagination: {
            limit: res.data.limit,
            page: res.data.page,
            total: res.data.total,
          },
        });
      }
      store.commit("handleUpdateLoading", false);
    } catch (error) {
      console.log(error);
      store.commit("handleUpdateLoading", false);
    } finally {
      store.commit("handleUpdateLoading", false);
    }
  },
};

export default { state, getters, mutations, actions };
