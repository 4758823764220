import { createStore } from "vuex";
import modal from "@/store/modules/modal";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import toast from "@/store/modules/toast";
import customPage from "@/store/modules/customPage";
import bank from "@/store/modules/bank";
import deposit from "@/store/modules/deposit";
import withdraw from "@/store/modules/withdraw";
import partnership from "@/store/modules/partnership";
import notice from "@/store/modules/notice";
import message from "@/store/modules/message";
import helpCenter from "@/store/modules/helpCenter";
import coupon from "@/store/modules/coupon";
import bankInfo from "@/store/modules/bank_info";
import dashboard from "@/store/modules/dashboard";
import settlement from "@/store/modules/settlement";
import affiliateStore from "@/store/modules/affiliateStore";
import payment from "@/store/modules/payment";
import wallet from "@/store/modules/wallet";
import admin from "@/store/modules/admin";
import broker from "@/store/modules/broker";

const store = createStore({
  state() {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    modal,
    auth,
    user,
    deposit,
    withdraw,
    toast,
    customPage,
    bank,
    partnership,
    notice,
    message,
    helpCenter,
    coupon,
    bankInfo,
    dashboard,
    settlement,
    affiliateStore,
    payment,
    wallet,
    admin,
    broker,
  },
});

export default store;
