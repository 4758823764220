<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.184 9.79102H13.334C12.9923 9.79102 12.709 9.50768 12.709 9.16602V3.34102C12.709 2.72435 12.9506 2.14935 13.384 1.71602C13.8173 1.28268 14.3923 1.04102 15.009 1.04102H15.0173C16.059 1.04935 17.0423 1.45768 17.7923 2.19935C18.5423 2.95768 18.9506 3.95768 18.9506 4.99935V7.01602C18.959 8.67435 17.8423 9.79102 16.184 9.79102ZM13.959 8.54102H16.184C17.1506 8.54102 17.709 7.98268 17.709 7.01602V4.99935C17.709 4.28268 17.4256 3.59935 16.9173 3.08268C16.409 2.58268 15.734 2.29935 15.0173 2.29102C15.0173 2.29102 15.0173 2.29102 15.009 2.29102C14.734 2.29102 14.4673 2.39935 14.2673 2.59935C14.0673 2.79935 13.959 3.05768 13.959 3.34102V8.54102Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.46745 19.0077C6.74245 19.291 7.10911 19.441 7.50077 19.441C7.89244 19.441 8.25911 19.291 8.54244 19.016L9.16959 18.3889C9.32812 18.2303 9.39004 17.9993 9.33203 17.7827C9.20703 17.3161 8.6237 17.1597 8.28209 17.5014L7.65077 18.1327C7.60077 18.1827 7.53411 18.191 7.50077 18.191C7.46744 18.191 7.40078 18.1827 7.35078 18.1327L5.96742 16.741C5.68408 16.4493 5.30908 16.3077 4.92575 16.3077C4.61742 16.3077 4.30909 16.4077 4.05076 16.5993L2.62579 17.666C2.54246 17.7327 2.45912 17.716 2.40912 17.691C2.36745 17.666 2.29244 17.616 2.29244 17.4993V4.99935C2.29244 3.14935 3.15077 2.29102 5.00077 2.29102H12.9591C12.8008 2.59935 12.7091 2.95768 12.7091 3.33268V11.0368C12.7091 11.3843 12.9908 11.666 13.3383 11.666C13.6858 11.666 13.9674 11.3843 13.9674 11.0368V3.33268C13.9674 2.75768 14.4341 2.29102 15.0091 2.29102C15.3508 2.29102 15.6341 2.00768 15.6341 1.66602C15.6341 1.32435 15.3508 1.04102 15.0091 1.04102H5.00911C2.49245 1.04102 1.05078 2.48268 1.05078 4.99935V17.4993C1.05078 18.0577 1.35909 18.5577 1.85909 18.8077C2.35909 19.0577 2.94244 18.9993 3.3841 18.666L4.80912 17.5993C4.89246 17.5327 5.00909 17.541 5.08409 17.616L6.46745 19.0077Z"
      fill="#FFFFFF"
    />
    <path
      d="M10 8.125H5C4.65833 8.125 4.375 7.84167 4.375 7.5C4.375 7.15833 4.65833 6.875 5 6.875H10C10.3417 6.875 10.625 7.15833 10.625 7.5C10.625 7.84167 10.3417 8.125 10 8.125Z"
      fill="#FFFFFF"
    />
    <path
      d="M9.375 11.459H5.625C5.28333 11.459 5 11.1757 5 10.834C5 10.4923 5.28333 10.209 5.625 10.209H9.375C9.71667 10.209 10 10.4923 10 10.834C10 11.1757 9.71667 11.459 9.375 11.459Z"
      fill="#FFFFFF"
    />
    <path
      d="M14.8039 16.6665L13.8163 15.9258V14.4169C13.8163 14.2652 13.6936 14.1426 13.5419 14.1426C13.3902 14.1426 13.2676 14.2652 13.2676 14.4169V16.0629C13.2676 16.1493 13.3082 16.2308 13.3773 16.2824L14.4746 17.1054C14.524 17.1425 14.5816 17.1603 14.639 17.1603C14.7226 17.1603 14.805 17.1227 14.8587 17.0503C14.9498 16.9293 14.9251 16.7573 14.8039 16.6665Z"
      fill="#FFFFFF"
    />
    <path
      d="M13.5417 12.5C11.5887 12.5 10 14.0887 10 16.0417C10 17.9947 11.5887 19.5833 13.5417 19.5833C15.4947 19.5833 17.0833 17.9947 17.0833 16.0417C17.0833 14.0887 15.4947 12.5 13.5417 12.5ZM13.5417 19.0347C11.8915 19.0347 10.5487 17.6918 10.5487 16.0417C10.5487 14.3915 11.8915 13.0487 13.5417 13.0487C15.1921 13.0487 16.5347 14.3915 16.5347 16.0417C16.5347 17.6918 15.1918 19.0347 13.5417 19.0347Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
