import { SSRRequest, AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const loginRequest = async (data) => {
  const res = await SSRRequest({
    url: "/api/auth/login",
    method: "POST",
    data,
  });
  return res;
};

export const getMeAuthRequest = async () => {
  const res = await AuthRequest({
    url: "/api/auth/me",
    method: "GET",
  });
  return res;
};

export const updateInfoMe = async (idx, data) => {
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/auth/update/${idx}`,
    method: "PUT",
    data,
  });
  return res;
};

// export const updateInfoMe = async (data) => {
//   const res = await AuthRequest({
//     baseURL: " https://broker.ziupay.com:2083",
//     url: '/transaction/update/bankinfo',
//     headers: {
//       "Content-Type": "application/json",
//     },
//     method: "POST",
//     data,
//   });
//   return res;
// };

export const signUpRequest = async (data) => {
  const res = await SSRRequest({
    url: "/authentication/ooo_browser/join",
    method: "POST",
    data,
  });
  return res;
};

export const logoutRequest = async (data) => {
  const res = await SSRRequest({
    url: "/authentication/ooo_browser/logout",
    method: "POST",
    data,
  });
  return res;
};
