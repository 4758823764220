const BASE_API_URL = process.env.VUE_APP_META_API_URL;
const BASE_API_URL2 = process.env.VUE_APP_META_API_URL2;

const SELECT_METHOD = {
  DEFAULT: "DEFAULT",
  USDT: "USDT",
  XRP: "XRP",
  OFFLINE: "OFFLINE",
  NO_BANK: "BANK",
  CREDIT: "CREDIT",
};

const SELECT_NETWORK = {
  DEFAULT: "DEFAULT",
  BNB20: "BNB20",
  TRC20: "TRC20",
  ERC20: "ERC20",
};

export { BASE_API_URL, BASE_API_URL2, SELECT_METHOD, SELECT_NETWORK };
