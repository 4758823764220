import { createRouter, createWebHistory } from "vue-router";
import { paths } from "@/routes/path/path";

let rt;

rt = paths["Ziupay"];

const routes = rt;

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  // document.title = res.data?.front_title || res.data?.templete || "EDGames";
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.icon);
  document.title = to.meta.title;
  next();
});

export default router;
