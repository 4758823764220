<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_3736_155538)">
      <path
        d="M7.99985 15.1628C7.03681 15.1628 6.25342 14.3794 6.25342 13.4164C6.25342 12.4533 7.03681 11.6699 7.99985 11.6699C8.96289 11.6699 9.74638 12.4533 9.74638 13.4164C9.74641 14.3794 8.96292 15.1628 7.99985 15.1628ZM7.99985 12.18C7.31815 12.18 6.76347 12.7347 6.76347 13.4164C6.76347 14.0981 7.31815 14.6527 7.99985 14.6527C8.68167 14.6527 9.23633 14.0981 9.23633 13.4164C9.23636 12.7347 8.68167 12.18 7.99985 12.18Z"
        fill="white"
      />
      <path
        d="M13.1474 10.6669C12.0158 10.6669 11.0952 9.74628 11.0952 8.61469C11.0952 7.48308 12.0158 6.5625 13.1474 6.5625C14.279 6.5625 15.1997 7.48308 15.1997 8.61469C15.1997 9.74628 14.279 10.6669 13.1474 10.6669ZM13.1474 7.07255C12.297 7.07255 11.6053 7.76432 11.6053 8.61469C11.6053 9.46506 12.297 10.1569 13.1474 10.1569C13.9977 10.1569 14.6896 9.46506 14.6896 8.61469C14.6896 7.76432 13.9977 7.07255 13.1474 7.07255Z"
        fill="white"
      />
      <path
        d="M2.852 10.6669C1.72041 10.6669 0.799805 9.74628 0.799805 8.61469C0.799805 7.48308 1.72041 6.5625 2.852 6.5625C3.98358 6.5625 4.90425 7.48308 4.90425 8.61469C4.90425 9.74628 3.98358 10.6669 2.852 10.6669ZM2.852 7.07255C2.00163 7.07255 1.30985 7.76432 1.30985 8.61469C1.30985 9.46506 2.00163 10.1569 2.852 10.1569C3.70236 10.1569 4.3942 9.46506 4.3942 8.61469C4.3942 7.76432 3.70233 7.07255 2.852 7.07255Z"
        fill="white"
      />
      <path
        d="M7.99988 6.58183C6.41572 6.58183 5.12695 5.29303 5.12695 3.70887C5.12695 2.12471 6.41572 0.835938 7.99988 0.835938C9.58404 0.835938 10.8729 2.12471 10.8729 3.70887C10.8729 5.29303 9.58404 6.58183 7.99988 6.58183ZM7.99988 1.34599C6.69694 1.34599 5.637 2.40592 5.637 3.70887C5.637 5.01181 6.69694 6.07175 7.99988 6.07175C9.30283 6.07175 10.3629 5.01181 10.3629 3.70887C10.3629 2.40592 9.30283 1.34599 7.99988 1.34599Z"
        fill="white"
      />
      <path
        d="M7.99995 5.40139C7.06711 5.40139 6.30811 4.64238 6.30811 3.70951C6.30811 2.77659 7.06711 2.01758 7.99995 2.01758C8.93291 2.01758 9.69191 2.77659 9.69191 3.70951C9.69191 4.64238 8.93291 5.40139 7.99995 5.40139ZM7.99995 2.52763C7.34833 2.52763 6.81819 3.0578 6.81819 3.70951C6.81819 4.36116 7.34836 4.89134 7.99995 4.89134C8.65166 4.89134 9.18187 4.36116 9.18187 3.70951C9.18187 3.0578 8.65166 2.52763 7.99995 2.52763Z"
        fill="white"
      />
      <path
        d="M7.99958 10.9608C7.85875 10.9608 7.74457 10.8466 7.74457 10.7057C7.74457 9.6932 6.92089 8.86942 5.90848 8.86942H4.64908C4.50822 8.86942 4.39404 8.75525 4.39404 8.61442C4.39404 8.47355 4.50822 8.35938 4.64908 8.35938H5.90848C7.20214 8.35938 8.25465 9.41198 8.25465 10.7057C8.25465 10.8466 8.14047 10.9608 7.99958 10.9608Z"
        fill="white"
      />
      <path
        d="M7.99964 10.9617C7.8588 10.9617 7.74463 10.8476 7.74463 10.7067V6.32731C7.74463 6.18644 7.8588 6.07227 7.99964 6.07227C8.1405 6.07227 8.25468 6.18644 8.25468 6.32731V9.24759C8.68492 8.70734 9.34808 8.36036 10.0909 8.36036H11.3503C11.4911 8.36036 11.6053 8.47454 11.6053 8.6154C11.6053 8.75623 11.4911 8.87041 11.3503 8.87041H10.0909C9.07845 8.87041 8.25468 9.69419 8.25468 10.7067C8.25471 10.8476 8.14053 10.9617 7.99964 10.9617Z"
        fill="white"
      />
      <path
        d="M7.99964 11.3555C7.8588 11.3555 7.74463 11.2413 7.74463 11.1005V10.7062C7.74463 10.5653 7.8588 10.4512 7.99964 10.4512C8.1405 10.4512 8.25468 10.5653 8.25468 10.7062V11.1005C8.25471 11.2413 8.14053 11.3555 7.99964 11.3555Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3736_155538">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
