<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.94129 2.77528C1.51505 3.20152 1.29102 3.99553 1.29102 5.66667V12.3333C1.29102 14.0045 1.51505 14.7985 1.94129 15.2247C2.36754 15.651 3.16155 15.875 4.83268 15.875H13.166C14.8371 15.875 15.6312 15.651 16.0574 15.2247C16.4837 14.7985 16.7077 14.0045 16.7077 12.3333V5.66667C16.7077 3.99553 16.4837 3.20152 16.0574 2.77528C15.6312 2.34903 14.8371 2.125 13.166 2.125H4.83268C3.16155 2.125 2.36754 2.34903 1.94129 2.77528ZM1.05741 1.89139C1.88116 1.06764 3.17048 0.875 4.83268 0.875H13.166C14.8282 0.875 16.1175 1.06764 16.9413 1.89139C17.765 2.71515 17.9577 4.00447 17.9577 5.66667V12.3333C17.9577 13.9955 17.765 15.2849 16.9413 16.1086C16.1175 16.9324 14.8282 17.125 13.166 17.125H4.83268C3.17048 17.125 1.88116 16.9324 1.05741 16.1086C0.233653 15.2849 0.0410156 13.9955 0.0410156 12.3333V5.66667C0.0410156 4.00447 0.233653 2.71515 1.05741 1.89139Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.041 5.66667C10.041 5.32149 10.3208 5.04167 10.666 5.04167H14.8327C15.1779 5.04167 15.4577 5.32149 15.4577 5.66667C15.4577 6.01185 15.1779 6.29167 14.8327 6.29167H10.666C10.3208 6.29167 10.041 6.01185 10.041 5.66667Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.875 9C10.875 8.65482 11.1548 8.375 11.5 8.375H14.8333C15.1785 8.375 15.4583 8.65482 15.4583 9C15.4583 9.34518 15.1785 9.625 14.8333 9.625H11.5C11.1548 9.625 10.875 9.34518 10.875 9Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.541 12.3333C12.541 11.9882 12.8208 11.7083 13.166 11.7083H14.8327C15.1779 11.7083 15.4577 11.9882 15.4577 12.3333C15.4577 12.6785 15.1779 12.9583 14.8327 12.9583H13.166C12.8208 12.9583 12.541 12.6785 12.541 12.3333Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.08255 6.01666C5.5947 6.01666 5.19922 6.41214 5.19922 6.9C5.19922 7.38785 5.5947 7.78333 6.08255 7.78333C6.5704 7.78333 6.96589 7.38785 6.96589 6.9C6.96589 6.41214 6.5704 6.01666 6.08255 6.01666ZM3.94922 6.9C3.94922 5.72179 4.90434 4.76666 6.08255 4.76666C7.26076 4.76666 8.21589 5.72179 8.21589 6.9C8.21589 8.0782 7.26076 9.03333 6.08255 9.03333C4.90434 9.03333 3.94922 8.0782 3.94922 6.9Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.77516 9.71945C8.28094 9.85602 9.476 11.0412 9.6215 12.5483C9.65467 12.8918 9.40304 13.1973 9.05946 13.2304C8.71588 13.2636 8.41046 13.012 8.37729 12.6684C8.28956 11.7598 7.56959 11.0457 6.66025 10.9642L6.65387 10.9636L6.65387 10.9636C6.27896 10.9261 5.89523 10.9259 5.51038 10.9637L5.50946 10.9638C4.59324 11.0522 3.87556 11.7633 3.78817 12.6684C3.75499 13.012 3.44957 13.2636 3.10599 13.2304C2.76241 13.1973 2.51078 12.8918 2.54395 12.5483C2.68988 11.0369 3.88863 9.86466 5.38887 9.7196C5.85282 9.67414 6.31788 9.67393 6.77516 9.71945Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
