import {
  getSendTRXUSDT,
  getCheckBalance,
  getCallTimePrice,
} from "@/api/broker/request";
import store from "..";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async handleActionSendTRXUSDT({ commit }, data) {
    const res = await getSendTRXUSDT(data);
    return res.data;
  },

  async handleActionCheckBalance({ commit }, data) {
    const res = await getCheckBalance(data);
    return res.data;
  },

  async handleActionGetCallTimePrice({ commit }, data) {
    const res = await getCallTimePrice(data);
    return res.data;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
