<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.57824 1.88847C2.6903 0.77641 4.35437 0.351242 6.49935 0.351242H11.4993C13.6443 0.351242 15.3084 0.77641 16.4205 1.88847C17.5325 3.00052 17.9577 4.6646 17.9577 6.80958V11.8096C17.9577 13.9546 17.5325 15.6186 16.4205 16.7307C15.3084 17.8427 13.6443 18.2679 11.4993 18.2679H6.49935C4.35437 18.2679 2.6903 17.8427 1.57824 16.7307C0.466183 15.6186 0.0410156 13.9546 0.0410156 11.8096V6.80958C0.0410156 4.6646 0.466183 3.00052 1.57824 1.88847ZM2.46212 2.77235C1.69918 3.53529 1.29102 4.78789 1.29102 6.80958V11.8096C1.29102 13.8313 1.69918 15.0839 2.46212 15.8468C3.22507 16.6097 4.47766 17.0179 6.49935 17.0179H11.4993C13.521 17.0179 14.7736 16.6097 15.5366 15.8468C16.2995 15.0839 16.7077 13.8313 16.7077 11.8096V6.80958C16.7077 4.78789 16.2995 3.53529 15.5366 2.77235C14.7736 2.00941 13.521 1.60124 11.4993 1.60124H6.49935C4.47766 1.60124 3.22507 2.00941 2.46212 2.77235Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.625 5.55958C9.625 4.29774 10.6548 3.26791 11.9167 3.26791C13.1785 3.26791 14.2083 4.29774 14.2083 5.55958V13.0596C14.2083 14.3214 13.1785 15.3512 11.9167 15.3512C10.6445 15.3512 9.625 14.3194 9.625 13.0596V5.55958ZM11.9167 4.51791C11.3452 4.51791 10.875 4.98809 10.875 5.55958V13.0596C10.875 13.6331 11.3389 14.1012 11.9167 14.1012C12.4882 14.1012 12.9583 13.6311 12.9583 13.0596V5.55958C12.9583 4.98809 12.4882 4.51791 11.9167 4.51791Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.79102 10.1429C3.79102 8.88106 4.82084 7.85124 6.08268 7.85124C7.34453 7.85124 8.37435 8.88106 8.37435 10.1429V13.0596C8.37435 14.3214 7.34453 15.3512 6.08268 15.3512C4.81047 15.3512 3.79102 14.3194 3.79102 13.0596V10.1429ZM6.08268 9.10124C5.51119 9.10124 5.04102 9.57142 5.04102 10.1429V13.0596C5.04102 13.6331 5.50489 14.1012 6.08268 14.1012C6.65417 14.1012 7.12435 13.6311 7.12435 13.0596V10.1429C7.12435 9.57142 6.65417 9.10124 6.08268 9.10124Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
