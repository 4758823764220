const state = {
  toggleShow: {
    mypageModal: false,
    partialCancel: false,
    paymentDetail: false,
    createStore: false,
    createUser: false,
    settlementApplication: false,
    settlementProductDetail: false,
    createWallet: false,
    createWalletByStore: false,
    createWalletByAdmin: false,
    transfer: false,
    bankInfo: false,
    walletInfo: false,
    settlementRequest: false,
    detailDeposit: false,
    detailWithdraw: false,
    detailSettlement: false,
    editMemo: false,
    manageDepositWithdrawMethod: false,
    walletInformation: false,
    managementDepositWithdrawMethod: false,
    modifyingStore: false,
  },
  isShowModal: false,
};

const getters = {};

const mutations = {
  handleToggle(state, payload) {
    state.toggleShow[payload] = !state.toggleShow[payload];
    const check = Object.values(state.toggleShow).filter((e) => e);
    if (check.length === 0) {
      document.querySelector("html").style.overflowY = "auto";
    } else {
      document.querySelector("html").style.overflowY = "hidden";
    }
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
