<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.28268 18.041C8.71602 18.6243 9.34935 18.9577 9.99935 18.9577C10.4577 18.9577 10.9078 18.7919 11.2853 18.4896C11.5549 18.2737 11.4944 17.8729 11.2192 17.6643C10.9415 17.4537 10.5513 17.5519 10.2184 17.6548C9.89107 17.756 9.53766 17.632 9.27435 17.2827L8.43268 16.1577C8.04102 15.6327 7.45768 15.3077 6.84102 15.2743H6.73268C6.15768 15.2743 5.59935 15.5244 5.14935 15.9827C4.51602 16.6577 4.09935 16.741 3.95768 16.6827C3.75768 16.6077 3.54102 16.1243 3.54102 15.2493V5.86602C3.54102 2.85768 4.06602 2.29102 6.84935 2.29102H13.141C15.9243 2.29102 16.4493 2.85768 16.4493 5.86602V9.92852C16.4493 10.276 16.731 10.5577 17.0785 10.5577C17.426 10.5577 17.7077 10.276 17.7077 9.92852V5.86602C17.7077 2.16602 16.641 1.04102 13.1493 1.04102H6.84935C3.35768 1.04102 2.29102 2.16602 2.29102 5.86602V15.2493C2.29102 17.0993 3.04935 17.6744 3.49935 17.8493C3.94102 18.0243 4.86602 18.1243 6.06602 16.841L5.60768 16.416L6.08268 16.841C6.28268 16.6327 6.53268 16.5243 6.78268 16.5327C7.03268 16.5493 7.26602 16.6827 7.44102 16.916L8.28268 18.041Z"
      fill="#FFFFFF"
    />
    <path
      d="M13.5247 9.79102H8.94141C8.59974 9.79102 8.31641 9.50768 8.31641 9.16602C8.31641 8.82435 8.59974 8.54102 8.94141 8.54102H13.5247C13.8664 8.54102 14.1497 8.82435 14.1497 9.16602C14.1497 9.50768 13.8664 9.79102 13.5247 9.79102Z"
      fill="#FFFFFF"
    />
    <path
      d="M13.5247 6.45898H8.94141C8.59974 6.45898 8.31641 6.17565 8.31641 5.83398C8.31641 5.49232 8.59974 5.20898 8.94141 5.20898H13.5247C13.8664 5.20898 14.1497 5.49232 14.1497 5.83398C14.1497 6.17565 13.8664 6.45898 13.5247 6.45898Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.48372 6.66667C6.02539 6.66667 5.65039 6.29167 5.65039 5.83333C5.65039 5.375 6.02539 5 6.48372 5C6.94206 5 7.31706 5.375 7.31706 5.83333C7.31706 6.29167 6.94206 6.66667 6.48372 6.66667Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.48372 10.0007C6.02539 10.0007 5.65039 9.62565 5.65039 9.16732C5.65039 8.70898 6.02539 8.33398 6.48372 8.33398C6.94206 8.33398 7.31706 8.70898 7.31706 9.16732C7.31706 9.62565 6.94206 10.0007 6.48372 10.0007Z"
      fill="#FFFFFF"
    />
    <path
      d="M17.0354 15.4907L15.9316 14.6629V12.9765C15.9316 12.807 15.7945 12.6699 15.625 12.6699C15.4554 12.6699 15.3184 12.807 15.3184 12.9765V14.8162C15.3184 14.9128 15.3637 15.0039 15.441 15.0615L16.6674 15.9813C16.7226 16.0227 16.787 16.0427 16.8511 16.0427C16.9446 16.0427 17.0366 16.0006 17.0967 15.9197C17.1985 15.7845 17.1709 15.5922 17.0354 15.4907Z"
      fill="#FFFFFF"
    />
    <path
      d="M15.6243 10.834C13.4416 10.834 11.666 12.6096 11.666 14.7923C11.666 16.9751 13.4416 18.7507 15.6243 18.7507C17.8071 18.7507 19.5827 16.9751 19.5827 14.7923C19.5827 12.6096 17.8071 10.834 15.6243 10.834ZM15.6243 18.1374C13.7801 18.1374 12.2792 16.6366 12.2792 14.7923C12.2792 12.9481 13.7801 11.4472 15.6243 11.4472C17.4689 11.4472 18.9695 12.9481 18.9695 14.7923C18.9695 16.6366 17.4686 18.1374 15.6243 18.1374Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
