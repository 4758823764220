<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6164 1.44922C11.8294 1.44922 11.1914 2.08721 11.1914 2.87422C11.1914 3.66123 11.8294 4.29922 12.6164 4.29922C13.4034 4.29922 14.0414 3.66122 14.0414 2.87422C14.0414 2.08722 13.4034 1.44922 12.6164 1.44922ZM9.94141 2.87422C9.94141 1.39686 11.139 0.199219 12.6164 0.199219C14.0938 0.199219 15.2914 1.39685 15.2914 2.87422C15.2914 4.35158 14.0938 5.54922 12.6164 5.54922C11.139 5.54922 9.94141 4.35158 9.94141 2.87422Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.38399 1.44922C2.59698 1.44922 1.95898 2.08722 1.95898 2.87422C1.95898 3.66122 2.59698 4.29922 3.38399 4.29922C4.17099 4.29922 4.80898 3.66123 4.80898 2.87422C4.80898 2.08721 4.17099 1.44922 3.38399 1.44922ZM0.708984 2.87422C0.708984 1.39685 1.90663 0.199219 3.38399 0.199219C4.86135 0.199219 6.05898 1.39686 6.05898 2.87422C6.05898 4.35158 4.86135 5.54922 3.38399 5.54922C1.90663 5.54922 0.708984 4.35158 0.708984 2.87422Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6164 11.6992C11.8294 11.6992 11.1914 12.3372 11.1914 13.1242C11.1914 13.9112 11.8294 14.5492 12.6164 14.5492C13.4034 14.5492 14.0414 13.9112 14.0414 13.1242C14.0414 12.3372 13.4034 11.6992 12.6164 11.6992ZM9.94141 13.1242C9.94141 11.6469 11.139 10.4492 12.6164 10.4492C14.0938 10.4492 15.2914 11.6469 15.2914 13.1242C15.2914 14.6016 14.0938 15.7992 12.6164 15.7992C11.139 15.7992 9.94141 14.6016 9.94141 13.1242Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.38399 11.6992C2.59698 11.6992 1.95898 12.3372 1.95898 13.1242C1.95898 13.9112 2.59698 14.5492 3.38399 14.5492C4.17099 14.5492 4.80898 13.9112 4.80898 13.1242C4.80898 12.3372 4.17099 11.6992 3.38399 11.6992ZM0.708984 13.1242C0.708984 11.6469 1.90663 10.4492 3.38399 10.4492C4.86135 10.4492 6.05898 11.6469 6.05898 13.1242C6.05898 14.6016 4.86135 15.7992 3.38399 15.7992C1.90663 15.7992 0.708984 14.6016 0.708984 13.1242Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
