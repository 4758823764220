<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.5697 8.125C15.6765 8.125 14.8928 7.83375 14.3815 7.37C13.8684 7.83375 13.0834 8.125 12.1884 8.125C11.2959 8.125 10.5128 7.83375 10.0009 7.37063C9.48905 7.83375 8.70592 8.125 7.81342 8.125C6.91842 8.125 6.13342 7.83375 5.6203 7.37C5.10905 7.83375 4.3253 8.125 3.43217 8.125C3.31217 8.125 3.19905 8.11875 3.08592 8.11125C1.8053 7.9875 0.832172 7.28813 0.644672 6.36875C0.597172 6.14125 0.627797 5.8925 0.737797 5.63187L2.80092 0.625H17.2009L19.2665 5.63687C19.374 5.89125 19.4053 6.14 19.3578 6.37C19.1697 7.28875 18.1972 7.9875 16.9365 8.10938C16.8028 8.11875 16.6897 8.125 16.5697 8.125ZM15.0072 6.05625C15.0072 6.44312 15.6753 6.875 16.5697 6.875C16.6622 6.875 16.749 6.86938 16.8359 6.86375C17.5322 6.79625 18.0309 6.45625 18.1234 6.15375L16.3634 1.875H3.6378L1.89092 6.11312C1.93217 6.42937 2.44217 6.79375 3.18592 6.86562C3.2528 6.86937 3.33967 6.875 3.43217 6.875C4.32655 6.875 4.99467 6.44312 4.99467 6.05625H6.24467C6.24467 6.44312 6.9153 6.875 7.81342 6.875C8.7078 6.875 9.37592 6.44312 9.37592 6.05625H10.6259C10.6259 6.44312 11.294 6.875 12.1884 6.875C13.0865 6.875 13.7572 6.44312 13.7572 6.05625H15.0072Z"
      fill="#FFFFFF"
    />
    <path
      d="M12.1875 8.12539C10.6106 8.12539 9.375 7.21664 9.375 6.05664C9.375 5.71164 9.655 5.43164 10 5.43164C10.345 5.43164 10.625 5.71164 10.625 6.05664C10.625 6.44289 11.2931 6.87539 12.1875 6.87539C13.0856 6.87539 13.7563 6.44289 13.7563 6.05664C13.7563 5.71164 14.0363 5.43164 14.3813 5.43164C14.7263 5.43164 15.0063 5.71164 15.0063 6.05664C15.0063 7.21664 13.7681 8.12539 12.1875 8.12539Z"
      fill="#FFFFFF"
    />
    <path
      d="M7.81289 8.12539C6.23227 8.12539 4.99414 7.21664 4.99414 6.05664C4.99414 5.71164 5.27414 5.43164 5.61914 5.43164C5.96414 5.43164 6.24414 5.71164 6.24414 6.05664C6.24414 6.44289 6.91477 6.87539 7.81289 6.87539C8.70727 6.87539 9.37539 6.44289 9.37539 6.05664C9.37539 5.71164 9.65539 5.43164 10.0004 5.43164C10.3454 5.43164 10.6254 5.71164 10.6254 6.05664C10.6254 7.21664 9.38977 8.12539 7.81289 8.12539Z"
      fill="#FFFFFF"
    />
    <path
      d="M3.125 11.4129C2.78 11.4129 2.5 11.1335 2.5 10.7879V6.82164L3.165 6.86414C3.25187 6.86977 3.33875 6.87539 3.43125 6.87539C4.32562 6.87539 4.99375 6.44289 4.99375 6.05664C4.99375 5.71164 5.27375 5.43164 5.61875 5.43164C5.96375 5.43164 6.24375 5.71164 6.24375 6.05664C6.24375 7.13727 5.17187 7.99977 3.75 8.11289V10.7879C3.75 11.1335 3.47 11.4129 3.125 11.4129Z"
      fill="#FFFFFF"
    />
    <path
      d="M17.5 19.3754H5.625C5.28 19.3754 5 19.096 5 18.7504C5 18.4048 5.28 18.1254 5.625 18.1254H16.25V8.11289C14.8281 7.99977 13.7562 7.13727 13.7562 6.05664C13.7562 5.71164 14.0362 5.43164 14.3812 5.43164C14.7262 5.43164 15.0062 5.71164 15.0062 6.05664C15.0062 6.44289 15.6744 6.87539 16.5687 6.87539C16.6612 6.87539 16.7481 6.86977 16.835 6.86414L17.5 6.82164V19.3754Z"
      fill="#FFFFFF"
    />
    <path
      d="M5.625 19.375C4.58687 19.375 3.5975 19.0631 2.76375 18.4731C1.42687 17.5469 0.625 16.0138 0.625 14.375C0.625 12.7362 1.42688 11.2031 2.76938 10.2731C3.5975 9.68688 4.58687 9.375 5.625 9.375C8.38187 9.375 10.625 11.6181 10.625 14.375C10.625 17.1319 8.38187 19.375 5.625 19.375ZM5.625 10.625C4.84687 10.625 4.1075 10.8575 3.48625 11.2975C2.475 11.9969 1.875 13.1463 1.875 14.375C1.875 15.6037 2.475 16.7531 3.48062 17.4487C4.1075 17.8925 4.84687 18.125 5.625 18.125C7.69312 18.125 9.375 16.4431 9.375 14.375C9.375 12.3069 7.69312 10.625 5.625 10.625Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.71704 15.3208L5.61323 14.493V12.8066C5.61323 12.6371 5.47617 12.5 5.30662 12.5C5.13706 12.5 5 12.6371 5 12.8066V14.6463C5 14.7429 5.04538 14.8339 5.12265 14.8916L6.34908 15.8114C6.40427 15.8528 6.46867 15.8727 6.53274 15.8727C6.62626 15.8727 6.71824 15.8307 6.77834 15.7498C6.88016 15.6145 6.85256 15.4223 6.71704 15.3208Z"
      fill="#FFFFFF"
    />
    <path
      d="M13.75 15H13.125C12.4356 15 11.875 14.4394 11.875 13.75V12.5C11.875 11.8106 12.4356 11.25 13.125 11.25H13.75C14.4394 11.25 15 11.8106 15 12.5V13.75C15 14.4394 14.4394 15 13.75 15ZM13.125 12.5V13.75H13.7506L13.75 12.5H13.125Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
