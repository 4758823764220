<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.4912 12.4417C14.7746 14.1501 12.3162 14.6751 10.1579 14.0001L6.23289 17.9167C5.94955 18.2084 5.39122 18.3834 4.99122 18.3251L3.17455 18.0751C2.57455 17.9917 2.01622 17.4251 1.92455 16.8251L1.67455 15.0084C1.61622 14.6084 1.80789 14.0501 2.08289 13.7667L5.99955 9.85006C5.33289 7.68339 5.84955 5.22506 7.56622 3.51672C10.0246 1.05839 14.0162 1.05839 16.4829 3.51672C18.9496 5.97506 18.9496 9.98339 16.4912 12.4417Z"
      stroke="#667184"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.74219 14.575L7.65885 16.4916"
      stroke="#667184"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.084 9.16663C12.7743 9.16663 13.334 8.60698 13.334 7.91663C13.334 7.22627 12.7743 6.66663 12.084 6.66663C11.3936 6.66663 10.834 7.22627 10.834 7.91663C10.834 8.60698 11.3936 9.16663 12.084 9.16663Z"
      stroke="#667184"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
