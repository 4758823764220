import { AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const getMakerCoin = async (params) => {
  const res = await AuthRequest({
    url: `/api/coins${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};

export const updateMakerCoin = async (data) => {
  const res = await AuthRequest({
    url: `/api/coins/multi`,
    method: "POST",
    data,
  });
  return res;
};

export const getCallbackAddressStore = async (params) => {
  const res = await AuthRequest({
    url: `/api/stores${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};
