<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M5.15804 6.50756C4.95804 6.50756 4.77471 6.41589 4.64971 6.24923C4.50805 6.05756 4.49138 5.80754 4.59971 5.5992C4.74138 5.31587 4.94138 5.04089 5.19971 4.79089L7.90805 2.07422C9.29138 0.699219 11.5414 0.699219 12.9247 2.07422L14.383 3.55758C14.9997 4.16591 15.3747 4.98258 15.4164 5.84924C15.4247 6.04091 15.3497 6.22422 15.208 6.34922C15.0664 6.47422 14.8747 6.53255 14.6914 6.49922C14.5247 6.47422 14.3497 6.4659 14.1664 6.4659H5.83305C5.63305 6.4659 5.44138 6.48256 5.24971 6.50756C5.22471 6.50756 5.19138 6.50756 5.15804 6.50756ZM6.54971 5.20756H14.0164C13.908 4.92423 13.733 4.6659 13.4997 4.43257L12.033 2.94921C11.1414 2.06588 9.68305 2.06588 8.78305 2.94921L6.54971 5.20756Z"
      fill="#FFFFFF"
    />
    <path
      d="M5.06638 5.26732C2.81497 5.5497 1.29746 7.13212 1.05794 9.36806C1.02069 9.71586 1.30826 10.0007 1.65804 10.0007C2.00782 10.0007 2.28573 9.71531 2.33388 9.36886C2.55471 7.77999 3.62358 6.7076 5.24971 6.50064C5.44138 6.47564 5.63304 6.45898 5.83304 6.45898H14.1664H14.1667C14.3416 6.45898 14.5165 6.45898 14.7164 6.4923C16.5664 6.70897 17.708 8.05065 17.708 10.0007V14.1673C17.708 16.3173 16.3164 17.709 14.1664 17.709H9.86638C9.5212 17.709 9.24138 17.9888 9.24138 18.334C9.24138 18.6792 9.5212 18.959 9.86638 18.959H14.1664C17.033 18.959 18.958 17.034 18.9414 14.1673V10.0007C18.9414 7.40898 17.333 5.54232 14.8747 5.25898C14.608 5.21732 14.3497 5.20898 14.1497 5.20898H5.81638C5.54971 5.20898 5.29138 5.23399 5.06638 5.26732Z"
      fill="#FFFFFF"
    />
    <path
      d="M18.3327 14.3743H15.8327C14.566 14.3743 13.541 13.3493 13.541 12.0827C13.541 10.816 14.566 9.79102 15.8327 9.79102H18.3327C18.6743 9.79102 18.9577 10.0743 18.9577 10.416C18.9577 10.7577 18.6743 11.041 18.3327 11.041H15.8327C15.2577 11.041 14.791 11.5077 14.791 12.0827C14.791 12.6577 15.2577 13.1243 15.8327 13.1243H18.3327C18.6743 13.1243 18.9577 13.4077 18.9577 13.7493C18.9577 14.091 18.6743 14.3743 18.3327 14.3743Z"
      fill="#FFFFFF"
    />
    <path
      d="M4.06533 20.1598C3.42366 20.1598 2.77766 19.917 2.27473 19.4358C0.995729 18.2045 -0.417673 16.2405 0.115605 13.9036C0.596855 11.7835 2.44815 10.834 4.06533 10.834C4.06533 10.834 4.06533 10.834 4.06966 10.834C5.68684 10.834 7.53814 11.7835 8.01939 13.9079C8.54833 16.2448 7.13493 18.2045 5.85593 19.4358C5.353 19.917 4.707 20.1598 4.06533 20.1598ZM4.06533 11.4843C2.80367 11.4843 1.18216 12.1563 0.752936 14.0467C0.284693 16.0887 1.56803 17.849 2.72997 18.9632C3.48002 19.6873 4.65497 19.6873 5.40503 18.9632C6.56263 17.849 7.84596 16.0887 7.38639 14.0467C6.95283 12.1563 5.32698 11.4843 4.06533 11.4843Z"
      fill="#FFFFFF"
    />
    <path
      d="M3.52325 16.2546C3.44088 16.2546 3.3585 16.2242 3.29347 16.1592L2.64313 15.5089C2.5174 15.3831 2.5174 15.175 2.64313 15.0493C2.76886 14.9236 2.97697 14.9236 3.1027 15.0493L3.52325 15.4698L5.0277 13.9654C5.15343 13.8397 5.36154 13.8397 5.48727 13.9654C5.61301 14.0911 5.61301 14.2992 5.48727 14.425L3.75304 16.1592C3.688 16.2242 3.60563 16.2546 3.52325 16.2546Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
