<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.50977 7.72461C1.85494 7.72461 2.13477 8.00443 2.13477 8.34961V12.0913C2.13477 13.9001 2.50112 15.005 3.16942 15.6733C3.83771 16.3416 4.94258 16.7079 6.75143 16.7079H11.2431C13.052 16.7079 14.1568 16.3416 14.8251 15.6733C15.4934 15.005 15.8598 13.9001 15.8598 12.0913V8.34961C15.8598 8.00443 16.1396 7.72461 16.4848 7.72461C16.8299 7.72461 17.1098 8.00443 17.1098 8.34961V12.0913C17.1098 14.0241 16.7261 15.5401 15.709 16.5572C14.6919 17.5743 13.1759 17.9579 11.2431 17.9579H6.75143C4.81862 17.9579 3.30265 17.5743 2.28553 16.5572C1.26841 15.5401 0.884766 14.0241 0.884766 12.0913V8.34961C0.884766 8.00443 1.16459 7.72461 1.50977 7.72461Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.43624 0.603641C6.46828 0.28422 6.7371 0.0410156 7.05812 0.0410156H10.9498C11.2712 0.0410156 11.5402 0.284742 11.5718 0.604563L12.1218 6.17123C12.3078 8.06239 10.8956 9.62435 8.99979 9.62435C7.104 9.62435 5.69178 8.06266 5.87779 6.1715L6.43624 0.603641ZM7.62357 1.29102L7.12179 6.29386C7.12177 6.29405 7.12181 6.29368 7.12179 6.29386C7.00813 7.45244 7.84576 8.37435 8.99979 8.37435C10.1539 8.37435 10.9916 7.45285 10.8778 6.29413C10.8778 6.29404 10.8778 6.29423 10.8778 6.29413L10.3835 1.29102H7.62357Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4546 0.246605C10.5731 0.115698 10.7414 0.0410156 10.918 0.0410156H13.4596C14.6153 0.0410156 15.5602 0.258827 16.2483 0.894405C16.9281 1.52238 17.239 2.45108 17.3954 3.58029C17.3964 3.58775 17.3973 3.59522 17.3981 3.60271L17.6316 5.8958C17.8347 7.93737 16.3139 9.62435 14.2596 9.62435C12.5644 9.62435 11.0541 8.26554 10.8796 6.57174L10.8794 6.56978L10.2961 0.728118C10.2785 0.552426 10.3361 0.377511 10.4546 0.246605ZM11.6085 1.29102L12.123 6.44363C12.123 6.44393 12.1231 6.44423 12.1231 6.44454C12.2324 7.50033 13.2052 8.37435 14.2596 8.37435C15.5718 8.37435 16.5176 7.32837 16.3878 6.02027C16.3878 6.02003 16.3878 6.0205 16.3878 6.02027L16.1557 3.74073C16.0118 2.71024 15.7565 2.14182 15.4001 1.81263C15.0507 1.48987 14.4873 1.29102 13.4596 1.29102H11.6085Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.70368 0.894405C2.39177 0.258827 3.33666 0.0410156 4.49235 0.0410156H7.03401C7.21059 0.0410156 7.37894 0.115711 7.49742 0.246637C7.61591 0.377563 7.67348 0.552501 7.65591 0.728206L7.07276 6.56801C7.07274 6.56827 7.07279 6.56774 7.07276 6.56801C6.90775 8.26525 5.39491 9.62435 3.70068 9.62435C1.64659 9.62435 0.125828 7.93761 0.328707 5.89624C0.328692 5.89639 0.328722 5.89609 0.328707 5.89624L0.55367 3.60495C0.554479 3.59671 0.555451 3.58849 0.556586 3.58029C0.712938 2.45108 1.02384 1.52238 1.70368 0.894405ZM1.79646 3.73964L1.57269 6.01875C1.4425 7.32718 2.38825 8.37435 3.70068 8.37435C4.75619 8.37435 5.72655 7.49974 5.8286 6.44736L5.82875 6.44577L6.3434 1.29102H4.49235C3.4647 1.29102 2.90126 1.48987 2.55184 1.81263C2.19558 2.1417 1.94041 2.70984 1.79646 3.73964Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99582 13.2439C7.50172 12.738 8.21008 12.541 9.0013 12.541C9.79253 12.541 10.5009 12.738 11.0068 13.2439C11.5127 13.7498 11.7096 14.4581 11.7096 15.2493V17.3327C11.7096 17.6779 11.4298 17.9577 11.0846 17.9577H6.91797C6.57279 17.9577 6.29297 17.6779 6.29297 17.3327V15.2493C6.29297 14.4581 6.48992 13.7498 6.99582 13.2439ZM7.8797 14.1277C7.69185 14.3156 7.54297 14.6489 7.54297 15.2493V16.7077H10.4596V15.2493C10.4596 14.6489 10.3108 14.3156 10.1229 14.1277C9.93505 13.9399 9.60174 13.791 9.0013 13.791C8.40086 13.791 8.06755 13.9399 7.8797 14.1277Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
